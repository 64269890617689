import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "edit-a-tab"
    }}>{`Edit a Tab`}</h1>
    <hr></hr>
    <p>{`To edit a tab within your mobile app or template, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the Mobile App or Template where you want to edit the tab. `}</li>
      <li parentName="ol">{`Select the tab from the Tab Manager list that you wish to edit. `}</li>
      <li parentName="ol">{`Select 'Edit Tab: (tab name)'. This action will open the Edit Tab page. `}</li>
      <li parentName="ol">{`Provide a title for the tab. This is how the tab will appear within your app. If you want a custom text color, enable 'Custom Color'. From the drop down, select a color from either the pallete presented or enter in a hexdecimal vlaue. `}</li>
      <li parentName="ol">{`Provide a subtitle for the tab. This details the purpose of the tab within your app. If you want a custom text color, enable 'Custom Color'. From the drop down, select a color from either the palette presented or enter in a hexdecimal value. `}</li>
      <li parentName="ol">{`Choose a graphic that will be used as the icon of the tab. You can upload your own image or select from an icon palette. If you want a custom icon color, enable 'Custom Color. From the drop down, select a color from either the palette presented or enter in a hexdecimal value. `}</li>
      <li parentName="ol">{`Set the visibility of the tab:`}
        <ul parentName="li">
          <li parentName="ul">{`Always visibility will always display the tab on your app homescreen regardless of whether the patron is logged in or not. `}</li>
          <li parentName="ul">{`Pre-Login visibility will display the tab on your app homescreen prior to the patron logging in. Once they have successfully logged in, the tab will disappear.`}</li>
          <li parentName="ul">{`Post-Login visibility will display the tab on your app homescreen after the patron has successfully logged in. Once they have successfully logged out, the tab will disappear. `}</li>
          <li parentName="ul">{`Staff Only visibility will display the tab on your app homescreen when a staff user has logged in. `}</li>
          <li parentName="ul">{`Never visibility will hide the tab on your app homescreen for all types of users. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Specify if you want the tab to always stay at the top of the tabs on your app homescreen. If this is enabled, patrons will not be able to change the order of the tab within the app's preferences. `}<em parentName="li">{`If more than one tab is pinned, they will display in the order they are listed in the Tab Manger.`}</em>{` `}</li>
      <li parentName="ol">{`Provide content that will display at the top of the opened tab, if desired. Text here can be entered in either free text or in HTML. `}</li>
      <li parentName="ol">{`Specify the contents of the tab:`}
        <ul parentName="li">
          <li parentName="ul">{`Content Item links to content previously created within the Content Management System. To learn how to create a Content item, please refer to the `}<a parentName="li" {...{
              "href": "https://appdocs.sol.us/create-content-item-and-schedule-it"
            }}>{`Create a Content Item and Schedule It`}</a>{` page for more information. `}</li>
          <li parentName="ul">{`Channel links to a single Channel. Most times a Channel will have location information. `}</li>
          <li parentName="ul">{`List links to a list of Channels that can power the "Nearest Libraries" tab within your app homescreen. `}
            <ul parentName="li">
              <li parentName="ul">{`If 'Order By - Title' is enabled, your app will sort the libraries alphabetically. `}</li>
              <li parentName="ul">{`If 'Order By - Proximity' is enabled, your app will arrange the list of libraries based on their distance from the current location of the patron's device, with the nearest library appearing at the top of the list. `}</li>
              <li parentName="ul">{`'Show Search Filter' will enable a search box at the top of the tab.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`HTML Content links to a tab that contents free text or HTML content. `}</li>
          <li parentName="ul">{`Library Search links to a tab that displays the ILS search page. This allows patron's to execute catalog queries of the ILS instance. `}
            <ul parentName="li">
              <li parentName="ul">{`If 'Include ISBN Scanner' is enabled, patron's will be able to use an ISBN scanner to find search results. `}</li>
              <li parentName="ul">{`If 'Include Results Indicators' is enabled, your app will display numbers as thumbnails for each search result to indicate the position. `}</li>
            </ul>
          </li>
          <li parentName="ul">{`Library Account links to the patron's account information to detail their loans, reservations and fines. `}</li>
          <li parentName="ul">{`Library Self-check links to either barcode or RFID self-check. To learn how to configure self-check, please refer to the `}<a parentName="li" {...{
              "href": "https://appdocs.sol.us/configure-barcode-self-check"
            }}>{`Configure Barcode Self-Check`}</a>{` or `}<a parentName="li" {...{
              "href": "https://appdocs.sol.us/configure-rfid-self-check"
            }}>{`Configure RFID Self-Check`}</a>{` page for more information. `}</li>
          <li parentName="ul">{`Library Login links to the login page. `}</li>
          <li parentName="ul">{`Language Selection links to language selection page. This allows patron's to decide which language they would like the app to be displayed in. `}</li>
          <li parentName="ul">{`QR Scanner links to a QR scanner that will load a URL. When a QR code is scanned, the URL will open within the in-app browser. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Provide content that will display at the bottom of the opened tab, if desired. Text here can be entered in either free text or HTML.`}</li>
      <li parentName="ol">{`Once you've completed the settings, click 'Save' to save the changes.`}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      